define('ButtonView',['require'],function(livequery) {
    var ButtonView = function(node) {
        this.node = node;
        this.state = 0;
        this.url = node.data('call-url');
        this.data = {};
        this.method = "POST";
        this.originClass = node.attr('class');
        this.originIconClass = node.find('i.fa').attr('class');
        this.successAnimation = node.data('button-view-success-animation');
        this.callData = {};

        node.click(function() {
            this.blur();
           return false;
        });

        node.click($.proxy(this.call, this));
    };

    ButtonView.prototype = {
        call: function() {
            if (!this.state) {
                $.ajax({
                    url: this.url,
                    method: this.method,
                    data: this.callData,
                    success: $.proxy(this.success, this),
                    error: $.proxy(this.error, this),
                });
                this.load();
                this.state = 1;
            }
        },

        success: function(response) {
            this.node.find('i.fa').removeClass('fa-spinner fa-spin');
            this.state = 0;

            if (this.successAnimation) {
                this.node.find('i.fa').attr('class', "").addClass("fa fa-check");
                setTimeout($.proxy(this.normalize, this), 2000);
            } else {
                this.normalize();
            }
        },

        error: function(response) {
            console.error(response);

            this.node.find('i.fa').attr('class', "").addClass("fa fa-exclamation-circle");
            this.state = 0;

            setTimeout($.proxy(this.normalize, this), 2000);
        },

        normalize: function() {
            this.node.attr('class', this.originClass);
            this.node.find('i.fa').attr('class', this.originIconClass);
        },

        load: function() {
            this.node.find("i.fa").attr('class', "").addClass('fa fa-spinner fa-spin');
        }
    };

    ButtonView.register = function() {
        $('.btn-view').livequery(function() {
            console.log("buttonview");
           $(this).data('button-view', new ButtonView($(this)));
        });
    };

    return ButtonView;
});

