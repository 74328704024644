define('TwigTemplate',['twig'], function(Twig) {

    var twig = Twig.twig,
        base = app.baseUrl + "templates";

    var TwigTemplate = function(templateName, params, callback) {
        var href = base + "/" + templateName;

        var node = null;

        twig({
            base: base,
            href: href,
            async: false,
            load: function(template) {
                console.log("Params:", params);
                var rendered = template.render(params);

                node = $($.parseHTML(rendered));

                if (callback) callback(node);
            }
        });

        return node;
    };

    return TwigTemplate;
});

