define('tables',['AttributeStatusToggler', 'Deleter', 'ButtonView'], function(livequery) {
    return function() {

        // Data tables functionality
        $(".date-table").livequery(function() {
            var table = $(this);

            var options = {columnDefs: [], language: {
                "sProcessing":   "Przetwarzanie...",
                "sLengthMenu":   "Pokaż _MENU_ pozycji",
                "sZeroRecords":  "Nie znaleziono pasujących pozycji",
                "sInfoThousands":  " ",
                "sInfo":         "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
                "sInfoEmpty":    "Pozycji 0 z 0 dostępnych",
                "sInfoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
                "sInfoPostFix":  "",
                "sSearch":       "Szukaj:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Pierwsza",
                    "sPrevious": "Poprzednia",
                    "sNext":     "Następna",
                    "sLast":     "Ostatnia"
                },
                "sEmptyTable":     "Brak danych",
                "sLoadingRecords": "Wczytywanie...",
                "oAria": {
                    "sSortAscending":  ": aktywuj, by posortować kolumnę rosnąco",
                    "sSortDescending": ": aktywuj, by posortować kolumnę malejąco"
                }
            }};

            var selectCol = $("th.select, th.no-sort", this);

            // Disable sorting
            selectCol.livequery(function() {
                var col = $(this),
                    index = col.index();

                options.columnDefs.push({
                    targets: index,
                    orderable: false
                });

                // Select all fuctionality
                col.find('input[type=checkbox]').change(function() {
                    var inputs = table.find('tbody td:nth-child(' + (index + 1) + ') input[type=checkbox]');

                    inputs.prop('checked', this.checked);
                });
            });

            var dt = table.DataTable(options);
        });

        // clickable rows
        $("tr.clickable").livequery(function() {
            var row = $(this),
                url = row.data('click-url');

            row.find('td:not(.select)').click(function() {
               window.location.href = url;
            });
        });

        // Make select cell clickable
        $("td, th").filter(".select").bind('click', function() {
            var input = $('input', this);

            input[0].checked ^= 1;

            input.change();
        });

        // Bulk actions
        $("a.table-bulk-action").livequery(function() {
            var link = $(this),
                url = link.attr('href');

            link.on('click', function() {
               return false;
            });

            link.on('click', function() {
                var selectedItems = [],
                    inputsChecked = [],
                    ajaxCallback;

                inputsChecked = $('.date-table:first').find('tbody input:checked');

                $.each(inputsChecked, function(i, e) {
                    selectedItems.push($(e).data('id'));
                });

                function ajaxCallback() {
                    $.ajax({
                        url: url,
                        data: {'selected': selectedItems},
                        format: 'json',
                        method: 'post',

                        success: function() {
                            var table = inputsChecked.parents('table'),
                                rows = inputsChecked.prop('checked', false).parents('tr');

                            link.trigger('bulk_action_success', {rows: rows});
                        }
                    });
                }

                if (link.hasClass('remove')) {
                    var comfirmModal = $("#remove-comfirm-modal");

                    comfirmModal.find('.count').text(selectedItems.length);

                    comfirmModal.modal('show');

                    function unbindCallbacks() {
                        comfirmModal.find('button').unbind('click');
                        comfirmModal.modal('hide');
                    }

                    comfirmModal.find('button').bind('click', unbindCallbacks);

                    console.log(comfirmModal.find('button.comfirm'));
                    comfirmModal.find('button.comfirm').click(ajaxCallback);

                } else {
                    ajaxCallback();
                }



               return false;
            });

            link.attr('href', "");
        });

        // Remove
        $('a.table-bulk-action.remove, a.table-bulk-action.archive').on('bulk_action_success', function(event, data) {
            var table = data.rows.parents('table:first');

            data.rows.each(function() {
                var row = $(this),
                    rowPos;

                rowPos = table.dataTable().fnGetPosition(row[0]);

                table.dataTable().fnDeleteRow(rowPos);
            });

        });

        // Refresh table
        $('a.table-bulk-action.refresh').on('bulk_action_success', function(event, data) {
            window.location = window.location.href;
        });

        // Toggler
        require('AttributeStatusToggler').register();
        require('Deleter').register();
        require('ButtonView').register();

        // Assign table
        $("#assign-forums-to-campaign-table").livequery(function() {
           var assignUrl = $(this).data('assign-url'),
               campaignId = $(this).data('campaign-id');

            $('.btn-assign', this).livequery(function() {
                var buttonView = $(this).data('button-view'),
                    serviceId = $(this).parents('tr:first').find('input[type=checkbox]').data('id');

                buttonView.url = assignUrl;

                buttonView.callData = {
                    'campaign_id': campaignId,
                    'services': [serviceId],
                };

                buttonView.success = function() {

                    this.node.parents('tr:first').find("*").css('overflow', "hidden").animate({
                        height: 0,
                        padding: 0,
                        fontSize: 0,
                        opacity: 0
                    }, function() {
                        $(this).remove();
                    });
                };
            });
        });

        $('.btn-assign-selected').click(function() {
           $('input[type=checkbox]:checked').parents('tr').find('.btn-assign').click();
        });

        $('.btn-assign-all').click(function() {
            console.log('all');
           $('input[type=checkbox]').parents('tr').find('.btn-assign').click();
        });


    };
});

