define('post/form',[],function() {
    var PostSpecialValuesCollection = function(node, inputs) {
        this.prototypeHtml = node.data('prototype');
        this.container = node;
        this.checkboxes = inputs;
        this.fields = [];

        node.find("> label").remove();
    };

    PostSpecialValuesCollection.prototype.build = function(id) {
        var specialForm = $(this.prototypeHtml.replace(/__name__/g, id)),
            selectSpecialAttribute = specialForm.find('.form-group.special select');

        selectSpecialAttribute.val(id);

        // this.container.append(specialForm);
        //
        specialForm.find('input[type=checkbox]').prop('checked', true);

        return specialForm;
    };

    PostSpecialValuesCollection.prototype.checkCurrent = function() {
        var forms = this.container.find("> div");

        forms.each($.proxy(function(i, e) {
            var selectSpecialAttribute = $(e).find('.form-group.special select'),
                id = selectSpecialAttribute.val();

            var checkbox = this.checkboxes.filter('[value=' + id + ']');

            if (checkbox[0]) {
                checkbox[0].checked = 1;

                this.fields[id] = $(e);

                this.fields[id].remove();
            } else {
                $(e).remove();
            }

        }, this));
    };

    PostSpecialValuesCollection.prototype.update = function() {
        this.checkboxes.each($.proxy(function(i, e) {
            var id = e.value,
                formId = this.getFormId(id);

            if (e.checked) {
                if(!(this.fields[id] instanceof $)) {
                    this.fields[id] = this.build(e.value);
                }

                this.fields[id].appendTo(this.container);
            } else {
                if (this.fields[id] instanceof $) {
                    this.fields[id].remove();
                }
            }
        }, this));
    };

    PostSpecialValuesCollection.prototype.getFormId = function(id) {
        return '#post_postsValues_' + id;
    };

    var formatCollectionForm = function(specialForm) {
        var selectSpecialAttribute = specialForm.find('.form-group.special select'),
            currentSpecialId = selectSpecialAttribute.val(),
            labelNode = specialForm.find('.form-group.value > label'),
            currentSpecialName;

        currentSpecialName = selectSpecialAttribute.find('option[value=' + currentSpecialId + ']').text();

        labelNode.text(currentSpecialName);
    };

    return function() {
        $("#post_postsValues").livequery(function() {
            var inputs = $(this).parents('form').find('.form-group.post-type input'),
                collection = new PostSpecialValuesCollection($(this), inputs);

            $(this).data('collection-controller', collection);

            inputs.change(function() {
                collection.update();
            });

            collection.checkCurrent();
            collection.update();
        });

        $("#post_postsValues > .form-group").livequery(function() {
            formatCollectionForm($(this));
        });
    };
});

