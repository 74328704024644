define('Deleter',['ButtonView', 'Confirmation', 'TwigTemplate'], function(ButtonView, Confirmation, TwigTemplate, livequery) {
    console.log(ButtonView);
    var Deleter = function(node) {
        ButtonView.call(this, node);

        this.url  = node.data('remove-url');
    };

    var parent = ButtonView.prototype;

    Deleter.prototype = $.extend(Object.create(parent), {
        call: function() {
            var buttonView = this;

            var confirmation = new Confirmation();

            confirmation.template = "modal_delete_comfirmation.html.twig";

            confirmation.params['item_name'] = this.node.parents('tr').find('td.name').text();

            confirmation.comfirm = function() {
                parent.call.call(buttonView);
            };

            confirmation.show();
        },

        success: function() {
            this.node.parents('tr:first').find("*").css('overflow', "hidden").animate({
                height: 0,
                padding: 0,
                fontSize: 0,
                opacity: 0
            }, function() {


                if ($(this).parents('table').hasClass('dataTable')) {
                    var dataTable = $(this).parents('table').dataTable(),
                        rowPos = dataTable.fnGetPosition($(this).parents('tr')[0]);

                    dataTable.fnDeleteRow(rowPos);
                } else {
                    $(this).remove();
                }
            });

        }
    });

    Deleter.register = function() {
        $('tr .btn.delete').livequery(function() {
            $(this).data('deleter', new Deleter($(this)));
        });
    };

    return Deleter;
});

