define('debug',[],function() {

    return function() {
        var args = ['panel-inf:'];

        [].push.apply(args, arguments)

        console.log.apply(console, args);
    };
});

