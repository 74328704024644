define('AttributeStatusToggler',['ButtonView'], function(ButtonView) {
    console.log(ButtonView);
    var AttributeStatusToggler = function(node) {
        ButtonView.call(this, node);

        this.url  = node.data('toggle-url');
    };

    var parent = ButtonView.prototype;

    AttributeStatusToggler.prototype = $.extend(Object.create(parent), {
        success: function(response) {
            parent.success.call(this);

            if (!response.finalStatus) {
                this.node.removeClass('btn-danger').addClass('btn-success');
                this.node.find('i.fa').removeClass('fa-lock').addClass('fa-unlock');
            } else {
                this.node.removeClass('btn-success').addClass('btn-danger');
                this.node.find('i.fa').removeClass('fa-unlock').addClass('fa-lock');
            }

            var hidden = this.node.parents('tr').find('._status .hide'),
                shown  = this.node.parents('tr').find('._status .label:not(.hide)');

            hidden.removeClass('hide');
            shown.addClass('hide');
        }
    });

    console.dir(AttributeStatusToggler.prototype);

    AttributeStatusToggler.register = function() {
        $('table.table.attributes-table .btn.toggle').livequery(function() {
           $(this).data('status-toggler', new AttributeStatusToggler($(this)));
        });
    };

    return AttributeStatusToggler;
});

