define('archive/table',['Deleter', 'ButtonView'], function(Deleter, ButtonView) {

    return function() {
        $('.btn-archive-restore').livequery(function() {
           var buttonView = $(this).data('button-view');

           buttonView.successAnimation = true;

           buttonView.success = function() {
                ButtonView.prototype.success.call(this);

                setTimeout($.proxy(function() {
                    Deleter.prototype.success.call(this);
                }, this), 1000)
           };
        });
    };
});

