require([
        'requirejs',
        'jquery.livequery',
        'debug',
        'formHelper',
        'tables',
        'menu',
        'campaign/form',
        'post/form',
        'archive/table'
    ],

    function(debug) {
        var debug = require('debug');

        var jquery = require('jquery');
        var formHelper = require('formHelper');
        var tableHelper = require('tables');
        var menu = require('menu');

        // Execute modules on ready
        $(function() {
           menu();
           formHelper();
           tableHelper();
        });

        require('campaign/form')();
        require('post/form')();
        require('archive/table')();
    }
);


define("app", function(){});

