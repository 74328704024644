define('Confirmation',['TwigTemplate'], function(twig) {
    var Confirmation = function() {};

    Confirmation.prototype = {
        template: "modal_comfirmation.html.twig",

        params: {},

        show: function() {
            twig(this.template, this.params, $.proxy(function(modal) {
                this.bind(modal);
            }, this));
        },

        bind: function(modal) {
            this.modal = modal;

            $('body').append(this.modal);

            this.modal.modal('show');

            this.modal.find('.btn.comfirm').click($.proxy(this.comfirm, this));
        },

        comfirm: function() {

        }
    }

    return Confirmation;
});

