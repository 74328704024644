define('campaign/form',[],function() {
    // TODO: Hide amplifier select

    var CampainAmlifierCollection = function(prototypeHtml, container, amplifiersSelect) {
        this.prototypeHtml = prototypeHtml;
        this.container = container;
        this.amplifiersSelect = amplifiersSelect;
        this.index = $('.campaign-amplifier').length;

        this.lastValue = amplifiersSelect.val();

        amplifiersSelect.change($.proxy(this.update, this));
    };

    CampainAmlifierCollection.prototype = {

        addAmplifier: function(i, name, amplifierId) {
            var html = this.prototypeHtml;

            html = html.replace('__verbose_name__', name);
            html = html.replace(/__amplifier_id__/g, amplifierId);
            html = html.replace(/__name__/g, i);

            var form = $.parseHTML(html);

            var amplifiersSelect = $(form).find("select[id$=amplifier]");

            amplifiersSelect.val(amplifierId);

            this.container.append(form);

            return form;
        },

        update: function() {
            var select = this.amplifiersSelect;

            $('.campaign-amplifier').each($.proxy(function(i, node) {
                var id = $(node).data('id');

                if (!select.val() || -1 == select.val().indexOf("" + id)) {

                    $(node).remove();
                };
            }, this));

            select.val() && $.each(select.val(), $.proxy(function(i, id) {

                if ($('#campaign-amplifier-' + id).length) {
                    return null;
                }

                var name = $('option[value=' + id + ']', select).text();

                this.addAmplifier(this.index++, name, id);
            }, this));
        }
    };

    return function() {
        $('#campaign-amplifiers').livequery(function() {

           var prototypeHtml = $(this).data('prototype'),
               amplifiersSelect = $("#campaign_amplifiers_list"),
               controller = new CampainAmlifierCollection(prototypeHtml, $(this), amplifiersSelect);

           $(this).data('collection-controller', controller);
        });
    };


});

