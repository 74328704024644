define('formHelper',['require'],function(debug) {
    return function() {
        $('.date').livequery(function() {
            var node = $(this);

            node.append($.parseHTML('<span class="input-group-addon add-on"><i class="fa fa-calendar"></i></span>'));
            node.datepicker({
                format: 'yyyy-mm-dd'
            }).on('change', function(){
               $('.datepicker').hide();
           });
        });

        $('.input-group.tags').livequery(function() {

            $('input', this).tagsInput();
        });

        $('.change-password-toggle').livequery(function() {
            var toggleCheckbox = this,
                passwordNodes = $('input[type=password]').parents('form > div');

            var toggle = function() {
                if (toggleCheckbox.checked == true) {
                    passwordNodes.show();
                } else {
                    passwordNodes.hide();
                }
            };

            $(toggleCheckbox).change(toggle).change();
        });

        $('#campaign_pick_campaign').change(function() {
           $(this).parents('form').submit();
        });

        $('input.submit-on-change').on('change', function() {
           $(this).parents('form').submit();
        });
    };
});

